.main-content {
  padding: 0px;
  background-color: #FFFFFF;
  width: 100%;
}

#body {
  padding: 0px;
  text-align: center;
}

.content-container {
  /* height: 100%; */
  padding: 30px;
}

.vertSpace {
  padding: 10px 10px 0;
  margin: 0px;
  align-items: center;
}

/* Tables css */
.ant-table-placeholder {
  z-index: -1;
}

.ant-table-header-column {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.addEditRemoveButton{
  color:#004173;
  cursor: pointer; 
  font-size: 1.5rem !important;
}

.addEditRemoveButton:hover,
.addEditRemoveButton:active { 
  color: #004173;
}

.main-content-size {
  min-height: calc( 100vh - 139px)
}