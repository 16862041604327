.datePickerComponent {
  padding: 0px ;
  width: 100%;
  margin-top: 4px;
}
.selectAligning {
  display: flex;
  align-items: center;
  padding: 0px;
}

.ant-calendar-picker-input.ant-input {
  height: 38px;
}

.ant-calendar-picker-input.ant-input.ant-input-disabled {
    height: 38px;
}