.header {
  padding: 0px;
}

header .ant-menu {
  background: #EDEDED;
  text-align: right;
  padding: 0 10px;
}

.header__item {
  background: #EDEDED !important;
  margin: 0px 10px !important;
  text-decoration: none;
}

.header__item span,
.header__item a {
  color: #606060;
  text-decoration: none;
}

.header__item :hover {
  color: white;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark .ant-menu-item-selected .anticon + span,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: rgb(37, 37, 37)
}

