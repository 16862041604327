.ant-table-tbody{
    font-size: 12px !important
}

.ant-table td {
    white-space: normal !important;
}

.ant-card-head{
    margin: 0 !important;
    padding: 0 !important;
}

.ant-card-head-title {
    padding: 0 0;
}

.ant-timeline-item-last {
    padding-bottom:  0%;
}


.accordion {
    font-size: 1.4rem;
}

.accordion p{
    margin: 1rem;
}

.paragraphTitle{
    margin-bottom: 0px !important;
    text-align: center;
}

.paragraphSubTitle{
    margin-bottom: 0px !important;
    color: #004173 !important;
    white-space: normal !important;
}

.paragraphSubSubTitle{
    white-space: normal !important ; 
    background: #004173 !important;
    color: white !important;
}

.paragraph{
    margin-bottom: 0px !important;
}

.contenedor{
    width: 90%;
    max-width: 1920px;
    padding: 1rem 5rem;
    display: grid;
    grid-gap: 20px;
    
}

@media only screen and (max-width: 767px) {
    .contenedor {
      grid-template-columns: repeat(1, 1fr);
      justify-content:space-between !important;
    }  
  }

  @media only screen and (min-width: 768px) and (max-width: 860px) {
    .contenedor {
      grid-template-columns: repeat(2, 1fr);
      justify-content:space-between !important;
    }
  }
  @media only screen and (min-width: 861px) and (max-width: 1023px) {
    .contenedor {
      grid-template-columns: repeat(3, 1fr);
      justify-content:space-between !important;
    }
  }

  @media only screen and (min-width: 1024px) {
    .contenedor {
      grid-template-columns: repeat(4, 1fr);
      justify-content:space-between !important;
    }
  }

.customCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0% 5% !important;
    width: 400px;
    border: 1px solid lightgray;
    box-shadow: 2px 2px 8px 4px #d3d3d3d1;
    border-radius:5px;
    min-height: 100%;
    text-decoration: none;
}

.customCollapse{
    margin: 0em 0.25em;
    border: 1px solid lightgray;
    box-shadow: 2px 2px 8px 4px #d3d3d3d1;
    border-radius:5px;
}

.customCollapseSelectedItem{
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius:5px;
}

.customTimeline{
     padding: 2em 3em 1em 3em;
}

.productPhoto {
    max-width: 100%;
    height: auto;
    margin: 0%;
    margin-bottom: 5%;
}

.userImg {
    max-width: 100%;
    height: auto;
    margin: 0%;
    margin-bottom: 5%;
}