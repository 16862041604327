.vertSpace1{
    margin:0 20px;
}

/* .aligning{
    display: flex !important;
    align-items: center;
    padding: 0px;
    margin:0 10px;
} */

.icon {
    width: 4%;
    max-width: 200px;
    float:left;
}

.icon2 {
    width: 8%;
    max-width: 50px;
    margin:0 10px;
}
