body {
    background-color: #0f2938 ;
    margin: 0;
    padding: 0;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important; 
    font-size: 14px !important;
    width: 100%;
}

.styles_modal__gNwvD {
    background-color: #FFFFFF !important;
}

.h1, h1 {
    font-size: 2.5rem;
}

.h2, h2 {
    font-size: 2.15rem;
}

.h3, h3 {
    font-size: 1.75rem;
}

.ant-popover {
    width: 30%;
}