/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #image-company {
    width: 40%;
    margin: auto;
  }
  .ant-layout-sider {
    flex: 0px !important;
    max-width: 90% !important;
    width: 90% !important;
    min-width: 90% !important;
    background-color: #0f2938;
    height: 0px;
  }

  .ant-layout-sider-collapsed {
    flex: 0px !important;
    max-width: 0% !important;
    width: 0% !important;
    min-width: 0% !important;
  }
  .ant-layout-sider-collapsed.logo {
    height: 0px;
  }
  .menu__user-info__avatar {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px !important;
  }
  .userInfo {
    font-size: 85% !important;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 576px) and (max-width: 767.99px) {
  #image-company {
    width: 40%;
    margin: auto;
  }
  .ant-layout-sider {
    flex: 0px !important;
    max-width: 90% !important;
    width: 90% !important;
    min-width: 90% !important;
    background-color: #0f2938;
    height: 0px;
  }
  .ant-layout-sider-collapsed {
    flex: 0px !important;
    max-width: 0% !important;
    width: 0% !important;
    min-width: 0% !important;
  }
  .ant-layout-sider-collapsed.logo {
    height: 0px;
  }
  .menu__user-info__avatar {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px !important;
  }
  .userInfo {
    font-size: 85% !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  #image-company {
    width: 40%;
    margin: auto;
  }
  .ant-layout-sider {
    flex: 0px !important;
    max-width: 90% !important;
    width: 90% !important;
    min-width: 90% !important;
    background-color: #0f2938;
    height: 0px;
  }
  .ant-layout-sider-collapsed {
    flex: 0px !important;
    max-width: 0% !important;
    width: 0% !important;
    min-width: 0% !important;
  }
  .ant-layout-sider-collapsed.logo {
    height: 0px;
  }
  .menu__user-info__avatar {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px !important;
  }
  .userInfo {
    font-size: 85% !important;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  #image-company {
    width: 100%;
    margin: 5px;
  }
  .ant-layout-sider {
    flex: 0px !important;
    max-width: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    background-color: #0f2938;
  }
  .ant-layout-sider-collapsed {
    flex: 0px !important;
    max-width: 0% !important;
    width: 0% !important;
    min-width: 0% !important;
  }
  .menu__user-info__avatar {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px !important;
  }
  .userInfo {
    font-size: 85% !important;
  }
}

/* Large devices (desktops, more than 1200px) */
@media (min-width: 1200px) {
  #image-company {
    width: 100%;
    margin: auto;
  }
  .ant-layout-sider {
    flex: 0px !important;
    max-width: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    background-color: #0f2938;
  }
  .ant-layout-sider-collapsed {
    flex: 0px !important;
    max-width: 0% !important;
    width: 0% !important;
    min-width: 0% !important;
  }
  .menu__user-info__avatar {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 25px !important;
  }
}

.menu {
  text-align: center;
  padding: 0px;
  margin: 0px;
  background-color: #0f2938;
}

.menu__user-info {
  padding: 10px;
}

.menu__user-info__user {
  color: white;
  padding: 0px;
}

#small-divider {
  margin: 0px;
}

#panel {
  background-color: inherit;
  border-right: 0px;
}

/* ------ */
/*Antd css components*/
.ant-menu-submenu-title {
  color: white;
}

.ant-menu-submenu-title:hover {
  color: #b5d6ff;
}

/* option text */
.ant-menu-item {
  background-color: #0f2938;
  border-bottom: 1px solid #214b63;
  margin: 0px !important;
  padding: 0px !important;
}
.ant-menu-item > a {
  color: white;
}

.ant-menu-item > a:hover {
  color: #1382e9;
}

/* option icon */
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-color: white !important;
  background-image: none !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0f2938;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}

.ant-menu-inline .ant-menu-item {
  width: 100%;
}

.clientsColor {
  background-color: rgb(194, 2, 2);
}

.ant-menu-item-clients {
  border-left: 10px solid rgb(194, 2, 2);
}

.userColor {
  background-color: #ff5400;
}

.ant-menu-item-users {
  border-left: 10px solid #ff5400;
}

.vendingMachineColor {
  background-color: #ffc50c;
}

.ant-menu-item-vendingMachine {
  border-left: 10px solid #ffc50c;
}

.dispensationRulesColor {
  background-color: rgb(154, 255, 95);
}

.ant-menu-item-dispensationRules {
  border-left: 10px solid rgb(154, 255, 95);
}

.productosColor {
  background-color: rgb(3, 100, 0);
}

.ant-menu-item-productos {
  border-left: 10px solid rgb(3, 100, 0);
}

.initialConfigColor {
  background-color: #00b6b9;
}

.ant-menu-item-initialConfig {
  border-left: 10px solid #00b6b9;
}

.dispensationsColor {
  background-color: #0074ff;
}

.ant-menu-item-dispensations {
  border-left: 10px solid #0074ff;
}

.configAndSupportColor {
  background-color: rgb(79, 0, 170);
}

.ant-menu-item-peripheral-device {
  border-left: 10px solid rgb(79, 0, 150);
}

.ant-menu-item-firmware-version{
  border-left: 10px solid rgb(79, 0, 140);
}

.ant-menu-item-vm-service-request{
  border-left: 10px solid rgb(79, 0, 130);
}

.privilegeByRoleColor {
  background-color: rgb(85, 0, 0);
}

.ant-menu-item-privilege-by-role {
  border-left: 10px solid rgb(85, 0, 0);
}

.myAccountColor {
  background-color: rgb(170, 41, 16);
}

.ant-menu-item-my-account {
  border-left: 10px solid rgb(170, 41, 16);
}

.smartVendingClinicalColor {
  background-color: rgb(223, 29, 29);
}

.ant-menu-item-patient-information {
  border-left: 10px solid rgb(223, 29, 29);
}

.ant-menu-item-locations {
  border-left: 10px solid rgb(223, 29, 29);
}
