.dropdownFullWidth, input {
  height: 100% !important;
}

.inputRow {
  height: 40px;
  text-align: left;
}

.input {
  padding: 0;
  height: 40px;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 38px !important;
}
