.userTableHeader {
  padding: 0 40px 0 10px;
}

.userTableHeaderElement {
  display: inline-block; 
  margin: 5px;
  background-color: rgba(240, 240, 240, 0.65);
}

.ant-collapse {
  font-size: 12px;
}

.ant-collapse .ant-collapse-item {
  border-bottom: 0px;
}

.ant-collapse-content .ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse-icon-position-right .ant-collapse-item .ant-collapse-header {
  padding: 0px;
  color: rgba(0, 0, 0, 0.65);
}

th {
  font-weight: 400;
}
