@media (max-width: 575.99px) {
    .loading-container {
        padding: 40px;
        box-sizing: border-box;
        text-align: center;
        background-color: white;
        border: 1px solid #D4D4D4;
        border-radius: 30px;
        height: 370px;
        max-width: 420px;
        margin: 0 auto;
        /* -webkit-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        /* -moz-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        box-shadow: 0px 0px 410px -8px rgba(237,234,237,1);

    }
    .loadingSubContainer{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .loadingSubContainerText{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
        max-height: 80px;
    }
    @keyframes App-logo-spin {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    .loadingViewText {
        font-size: 1.7rem;
        color: #0f2938;
    }
}

@media (min-width: 576px) and (max-width: 767.99px) { 
    .loading-container {
        padding: 40px;
        box-sizing: border-box;
        text-align: center;
        background-color: white;
        border: 1px solid #D4D4D4;
        border-radius: 30px;
        height: 370px;
        max-width: 420px;
        margin: 0 auto;
        /* -webkit-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        /* -moz-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        box-shadow: 0px 0px 410px -8px rgba(237,234,237,1);

    }
    .loadingSubContainer{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .loadingSubContainerText{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
        max-height: 80px;
    }
    @keyframes App-logo-spin {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    .loadingViewText {
        font-size: 1.7rem;
        color: #0f2938;
    }
}

@media (min-width: 768px) and (max-width: 991.99px) { 
    .loading-container {
        padding: 40px;
        box-sizing: border-box;
        text-align: center;
        background-color: white;
        border: 1px solid #D4D4D4;
        border-radius: 30px;
        height: 370px;
        max-width: 420px;
        margin: 0 auto;
        /* -webkit-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        /* -moz-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        box-shadow: 0px 0px 410px -8px rgba(237,234,237,1);

    }
    .loadingSubContainer{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .loadingSubContainerText{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
        max-height: 80px;
    }
    @keyframes App-logo-spin {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    .loadingViewText {
        font-size: 1.7rem;
        color: #0f2938;
    }
}

@media (min-width: 992px) and (max-width: 1199.99px) { 
    .loading-container {
        padding: 40px;
        box-sizing: border-box;
        text-align: center;
        background-color: white;
        border: 1px solid #D4D4D4;
        border-radius: 30px;
        height: 370px;
        max-width: 420px;
        margin: 0 auto;
        /* -webkit-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        /* -moz-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        box-shadow: 0px 0px 410px -8px rgba(237,234,237,1);
    }
    .loadingSubContainer{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .loadingSubContainerText{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
        max-height: 80px;
    }
    @keyframes App-logo-spin {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    .loadingViewText {
        font-size: 1.7rem;
        color: #0f2938;
    }
}

@media (min-width: 1200px) {
    .loading-container {
        padding: 40px;
        box-sizing: border-box;
        text-align: center;
        background-color: white;
        border: 1px solid #D4D4D4;
        border-radius: 30px;
        height: 370px;
        max-width: 420px;
        margin: 0 auto;
        /* -webkit-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        /* -moz-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1); */
        box-shadow: 0px 0px 410px -8px rgba(237,234,237,1);
    }
    .loadingSubContainer{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .loadingSubContainerText{
        height: 50%;
        padding: 10% 0 0 0 ;
    }
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
        max-height: 80px;
    }
    @keyframes App-logo-spin {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    .loadingViewText {
        font-size: 1.7rem;
        color: #0f2938;
    }
}