@import '~antd/dist/antd.css';

/* App css */
.App {
  width: 100%;
  margin: 0px;
  height: 100vh;
}

/* Main Content */
.content {
  /* margin: 0px; */
  padding: 0px;
  box-sizing: border-box;
}