.warning-container {
  padding: 1em ;
  padding-bottom: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: white;
  border: 1px solid #D4D4D4;
  height: 40vh;
  width: 50vh;
  margin: 0 auto;
  margin-top: 1%;
  -webkit-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1);
  -moz-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.warning-container .header {
  margin: 26px 0 0 0;
  color: #363B3E;
  font-size: 45px;
  font-weight: 500;
}

.warning-container .error {
    margin: 0 0 0 0;
    color: #9BA0A3;
    margin-bottom: 35px;
}

.warning-container hr {
    border: 1px solid #E7E7E7;
    border-top: 0;
}

.warning-container .bottom-content {
    display: inline-block;
    height: 120px;
    line-height: 120px;
}

.warning-container .bottom-content > span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.warning-container .check-url {
    margin-top: 0;
    font-weight: 500;
}
.warning-container .otherwise {
    margin-top: 0;
}

.warning-container a {
    color: #2990EA;
    text-decoration: inherit;
}
