#productsTable{
    max-height: 350px !important;
    overflow-y: auto !important;
}

.alignCalendars{
    text-align: left !important;
}

.alignLabel{
    text-align: center !important;
}

/* .ant-table-body{
    min-height: 250px
} */