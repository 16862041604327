/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 599.99px) { 
    .company-logo {
        width: 25%;
        top: 10%;
        max-width: 150px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 600px) and (max-width: 767.99px) { 
    .company-logo {
        width: 25%;
        top: 10%;
        max-width: 150px;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.99px) { 
    .company-logo {
        width: 50%;
        top: 10%;
        max-width: 150px;
    }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.99px) { 
    .company-logo {
        width: 50%;
        top: 10%;
        max-width: 150px;
    }
}

/* Large devices (desktops, more than 1200px) */
@media (min-width: 1200px) { 
    .company-logo {
        width: 50%;
        top: 10%;
        max-width: 150px;
    }
}

#footer {
    min-height: 68px;
    box-sizing: border-box;
    display: inline-block;
    background-color: #EDEDED;
    width: 100%;
    text-align: center;
    padding: 15px 5px;
    vertical-align: middle;
}

.footer__link-section__divider {
    background: #f80404; 
}

.footer__link {
    color: #000000;
    vertical-align: middle;
}

#footer-copy-right {
    color: #000000;
    font-style: italic;
    margin: 0;
}