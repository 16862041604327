.menuTitle {
    border-bottom-style: inset !important;
    border-bottom-color: rgba(0,0,0,.125);
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  #menuTitle {
    border-bottom-style: inset !important;
    border-bottom-color: rgba(0,0,0,.125);
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .alignTitle{
    display: flex;
    align-items: center;
    justify-content: center;
}
.centeredIcon{
  padding: 10px 20px 10px 0px
}