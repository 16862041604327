.error-message {
  color: rgb(228, 11, 11);
  font-weight: bold;
}

.outer {
  display: flex;
}

.location-info {
  font-weight: bold;
}

.location-div {
  display: flex;
  gap: 5%;
}

@media (max-width: 800px) {
  .location-div {
    justify-content: center;
  }
}

.button-space {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
}

@media (min-width: 1000px) {
  .button-margin {
    width: 70%;
  }
}

@media (max-width: 999.9px) {
  .button-margin {
    width: 0%;
  }
}

@media (min-width: 1000px) {
  .button-container-width {
    width: 35%;
  }
}

@media (max-width: 999.9px) {
  .button-container-width {
    width: 100%;
  }
}

.button-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.addEditRemoveButton{
  color:#004173;
  cursor: pointer; 
  font-size: 1.5rem !important;
}

.addEditRemoveButton:hover,
.addEditRemoveButton:active { 
  color: #004173;
}
