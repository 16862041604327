/* .selectAligning{
    display: flex;
    align-items: center;
    padding: 0px;
    height: 30px;
} */
.icon{
    margin:0 10px
}
.text{
    padding: 0;
    height: 40px;
    margin: 0

}

.text2{
    vertical-align: middle;
}

/* .aligning{
    display: flex;
    align-items: center;
    padding: 0px;
    height: 100%;
    width: 100%;
} */


