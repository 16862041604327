.selectOption-container {
    padding: 100px;
    box-sizing: border-box;
    text-align: center;
    background-color: white;
    border: 2px solid #D4D4D4;
    color: #6b767c !important;
    height: auto;
    width: auto;
    margin: 2% 20%;
    -webkit-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1);
    -moz-box-shadow: 0px 0px 41px -8px rgba(237,234,237,1);
    box-shadow: 0px 0px 41px -8px rgba(237,234,237,1);
  }
  
  /* .warning-container .header {
    margin: 26px 0 0 0;
    color: #363B3E;
    font-size: 45px;
    font-weight: 500;
  } */