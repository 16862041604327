.adjustmentSummary .ant-table td {
  overflow: hidden;
  padding: 0.5rem !important;
}

.adjustmentTable .ant-table th {
  padding: 0.5rem !important;
  background-color: #188fff1c !important;
}

.adjustmentTable .ant-table-body {
  margin: 0rem !important;
}

.adjustmentTable .ant-table td {
  word-break: normal !important;
  padding: 0.5rem !important;
}

.blue {
  background-color: #188fff1c;
}

.customCollapse{ 
  margin: 0em 0.25em;
  box-shadow: 2px 2px 8px 4px #d3d3d3d1;
  border-radius:5px;
  width: 95%;
  background-color: #188fff1c;
  border: 2px solid #1890ff;
}

.customPanel{ 
  display: flex !important;
  margin: 0.5em;
}

.customImg{
  border-top-left-radius:2px !important;
  border-top-right-radius:2px !important;
}

.customCardAdj{
  margin: 0 !important;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 8px 4px #d3d3d3d1;
  border-radius:5px;
  width: 100% !important;
  min-height: 100%;
}

.customCard .ant-card-body{
  padding: 1rem;
}

.panelTitle{
  margin-bottom: 0px !important;
  color: #004173 !important;
  white-space: normal !important;
  font-size: 20px !important;
}

.panelSubTitle{
  margin-bottom: 0px !important;
  white-space: normal !important;
  font-size: 14px !important;
}

 .ant-modal-header{
padding-bottom: 0 !;
}

.detailRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  text-align: center;
  
}

@media (min-width: 767px) {
  .detailRow {
    flex-direction: row;
  }

  .detailRow > div {
    flex: 1;
    margin-right: 2.5em;
    
  }

  .detailRow > div:last-child {
    margin-right: 0; 
  }
}
