.machine {
    width: 100% !important;
    text-align: center !important;
    font-size: 13px;
}

.machine,
.machine tr,
.machine th,
.machine td  {
    border: 1px solid #a1a1a1; 
}

.machine > tbody td {
    height: 45px;
}

.trayHeader{
    background: #f5f5f5 !important;
    color: #4e4e4e !important;
    min-width: 10px;
}

.traySubHeader{
    background: #f5f5f5 !important;
    color: #4e4e4e !important;
    min-width: 10px;
    height: 3px;
}

.suggested_tray{
    background: #a7ffb7;
    color: #07ab06;
    cursor: pointer;
}

.suggested_slot {
    background: #a7ffb7;
    color: #0ca00c;
    cursor: pointer;
}

.emptySlot {
    cursor:no-drop;
}

.product_slot{
    padding: 2px;
    transition: 0.2s;
    cursor: pointer;
}

.productPlaced{
    background: #BACBF5;
    color: #4e4e4e;
}

.productPlacedWihtoutSetup{
    background: #f7d5af;
    color: #4e4e4e;
}

.slotText1{
    font-size: 11px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
    font-weight: bold !important;
}

.slotText{
    font-size: 11px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
}

.addTray{
    margin-top: 10px;
    padding: 0px;
    width: 140px;
    background-color: #ffffff00 !important;
    border: 1px solid #028b09 !important;
    border-radius: 7px;
    color: #0f2938  !important
}