/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.99px) { 
    .antdTable .ant-table-body {
        max-height: 240px !important;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 576px) and (max-width: 767.99px) { 
    .antdTable .ant-table-body {
        max-height: 240px !important;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.99px) { 
    .antdTable .ant-table-body {
        max-height: 240px !important;
    }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.99px) { 
    .antdTable .ant-table-body {
        max-height: 400px !important;
    }
}

/* Large devices (desktops, more than 1200px) */
@media (min-width: 1200px) { 
    .antdTable .ant-table-body {
        max-height: 400px !important;
    }
} 


