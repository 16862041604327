.ant-transfer-list{
    width:45%
}
.sequence{
    width: 50% !important;
}


.button {
    border-color: #0f2938;
    color: #0f2938 ;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: 40px;
}
  
 