.vendingMachinePass{
    font-family: 'Courier New', Courier, monospace
}

#vendingMachine{
    height: 330px;
}

#div_center_msgError {
    float:center; 
    text-align:center;
}
/* 
.ant-tabs{
        -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
     zoom: 1;
    overflow: visible
} */