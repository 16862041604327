.selectAligning {
  display: flex;
  align-items: left;
  padding: 0px;
}

.selectAligning > span{
    text-align: left;
}

.icon{
    margin:0 10px
}
.dropdownFullWidth {
  padding: 0px;
  height: 40px;
}
.input {
  padding: 0 auto;
  max-width: 100%;
  min-width: 50%;
  margin-top: 2px;
}

.css-pujnn1 {
  background-color: #004173 !important;
}
.css-gji98i {
  background-color: #004173 !important;
}
.css-1frpy8x {
  background-color: #004173 !important;
}

/*-- ANTD select multiple*/
.ant-select-selection--multiple {
  padding-bottom: 0px;
  min-height: 40px;
}
.ant-select-selection__rendered {
  min-height: 40px;
}
.ant-select-selection--single {
  height: 40px;
}
