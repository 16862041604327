.center {
    align-items: center;    
    margin: 10px auto;
}
.ant-btn {
    background-color: #0f2938 !important;
    color: #EFF7FA !important;
    border: solid #0f2938 !important;
    height: 40px !important;
    border-radius: 4px;
}

.ant-btn[disabled]{
    background-color: #a7b4bb !important;
    color: #EFF7FA !important;
    border: solid #a7b4bb !important;
    border-radius: 4px;
}

.button {
    border-color: #0f2938;
    color: #0f2938 ;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    align-items: center !important;
    margin: 5px
}
.button:hover{
    text-shadow: 0 10px 10px rgba(255, 255, 255, 0.541);
}
.button:active {
    position:relative;
    top:1px;
}