  /* 
  .table {
    border: 1px solid #dee2e6;
    min-height: 350px !important; 
    max-height: 350px !important;
    overflow-y: scroll !important;
  }

  .tableTh {
    text-align: left !important;
    font-size: 14px !important;
    background: #FAFAFA;
    padding: 14px !important;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
    font-weight: normal;
  }

  .tableTr {
    text-align: left !important;
    padding: 14px !important;
    font-size: 14px !important;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
  }
  
  .tableTd {
    text-align: left !important;
    padding: 14px !important;
    font-size: 14px !important;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
  }
  */

  .onlyOverflow {
    overflow-y: scroll !important; 
    overflow-x: hidden !important; 
    max-height: 250px !important;
    min-height: 250px !important;
  }

