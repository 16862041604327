.style-alert-msn {
  margin: 3% auto;
  max-width: 40%;
  box-shadow:  2px 2px 8px 4px #d3d3d3d1;
  border-radius:5px;
  background-color: #188fff1c;
  border: 2px solid #1890ff;
}

.style-spin-msn {
  margin: 3% auto;
}

.style-result-info {
  padding: 1em;
}

.style-result-info-msn {
  font-size: 16px !important;
  margin: 0;
}

.footer-style {
  margin: 5% auto;
}
